export const flags = Object.freeze({
  ALARM_DETAIL_PAGE_SHARE_BUTTON: "ui.alarm-detail-page.share-button",
  ALARM_DETAIL_PAGE_FEEDBACK_BUTTON: "ui.alarm-detail-page.feedback-button",
  ALARM_DETAIL_PAGE_WORK_ORDER_BUTTON: "ui.alarm-detail-page.work-order-button",
  USER_SETTINGS_ORG_NOTIFICATIONS: "ui.settings.organization.notifications",
  ALARM_NOTIFICATION_ENGINE_1_1: "alarm.notification-engine-1.1",
  POI_NAME_ENDPOINT_TO_BE_UPDATED: "ui.view.exemple.of.data",
  NAVBAR_ACTIONS_HIDDEN: "ui.navigation-bar.actions.hidden",
  USER_PROFILE_RESOURCES_BUTTON: "ui.user-profile.resources-button",
  CAMERA_PAGE_SUPPORT_VIEW_VERSIONS: "ui.camera-page-support-view-versions-tab",
  CAMERA_SCREEN_LEVEL2: "ui.view.camera.screen.level2",
  EVENTS_SOURCE_ACTIVITY_FILTERS: "ui.kuva.events.source-activity-filters",
  PROFILE_TAB: "ui.view.user.page.level1",
  DASHBOARD_SPLIT_SCREEN: "ui.view.dashboard.split.screen",
  STREAM_ID_MANAGEMENT_TAB: "ui.manage-stream-id-tab",
  ORG_SETTINGS_TAB: "ui.organization-settings-tab",
  HEALTH_AND_DETAILS: "ui.camera-screen-level2.health-and-details",
  CAMERA_ERRORS: "ui.camera-screen-level2.camera-errors",
  TIMEOUT: "ui.kuva.initialize.timeout",
  DWELLING: "ui.camera-screen-level2.dwelling",
  ORGANIZATION_API_V2: "ui.api.organizations.v2",
  USER_API_V2: "ui.api.users.v2",
  ALARM_API_V2: "ui.api.alarms.v2",
  ALGORITHM_API_V2: "ui.api.algorithm.v2",
  IMAGE_PICTURE_QUALITY_INDICATOR: "ui-image-picture-quality-indicator-phase-1"
});

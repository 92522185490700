import { keyBy } from "lodash";

export const filterSelectedItems = (items, selected) => {
  if (!items) {
    return [];
  }

  return items.filter(item => item.selected === selected);
};

export const updateItem = (items, key, selected) =>
  items.map(item => {
    return item.value === key ? { ...item, selected } : item;
  });

export const updateAll = (items, selected, itemsToUpdate = []) => {
  if (itemsToUpdate.length) {
    const filteredValues = keyBy(itemsToUpdate, "value");
    return items.map(item => {
      const toUpdate = !!filteredValues[item.value];
      return toUpdate ? { ...item, selected } : item;
    });
  }

  return items.map(item => ({ ...item, selected }));
};

export const getSelectedValues = items => {
  if (!items) {
    return "";
  }
  return items.map(item => item.value).join(",");
};

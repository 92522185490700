import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { AlarmsActions } from "~/store/slices/alarms";

export const useRefetchAlarms = () => {
  const dispatch = useDispatch();
  const { deviceId } = useParams();
  const { streams } = useSelector(state => state.cameraState);
  const streamId = streams.byDeviceId[deviceId]?.id;

  const clearAllAlarms = useCallback(() => {
    dispatch(AlarmsActions.clearAllAlarms());
  }, [dispatch]);

  const refetchAlarms = useCallback(() => {
    if (!deviceId) return;

    dispatch(AlarmsActions.clearAllAlarms());
    dispatch(
      AlarmsActions.getAlarmByPagination({
        ...(streamId ? { streamId } : { deviceId }),
        page: 1,
        pagesize: 25
      })
    );
  }, [dispatch, deviceId, streamId]);

  return {
    clearAllAlarms,
    refetchAlarms
  };
};

import BaseApi from "./BaseApi";

class TelemetryAPI extends BaseApi {
  constructor() {
    super(`${process.env.REACT_APP_KUVA_API_URL}/telemetry/v1`, 10000);
  }

  /* retrieve latest snapshot */
  getTelemetryByOrg = (orgId, source) => {
    console.log(
      `%c calling: ${this.axiosInstance.defaults.baseURL}/telemetries/${orgId}`,
      "color: #00aa66"
    );
    return this.axiosInstance.get(`/telemetries/${orgId}`, {
      cancelToken: source.token
    });
  };
}

export default TelemetryAPI.Instance();

import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

export const updateCameraProperties = createAsyncThunk(
  "cameras/updateCameraProperties",
  async (payload, thunkAPI) => {
    try {
      const updatedProperties = await CameraAPI.updateCameraProperties(payload);

      return updatedProperties.data.response;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

class ApiManager {
  static getAccessTokenSilently = null;
  static logout = null;

  static setLogout(logout) {
    ApiManager.logout = logout;
  }

  static setAccessTokenRetriever(getAccessTokenSilently) {
    ApiManager.getAccessTokenSilently = getAccessTokenSilently;
  }
}

export default ApiManager;

import {
  Box,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";

import { LoadingSpinner } from "@kuva/ui-components";

import TruncatedTextWithTooltip from "~/components/TruncatedTextWithTooltip";

import TableHeaderCell from "./TableHeaderCell";
import TablePaginationActions from "./TablePaginationActions";
import { useTableStyles } from "./styles";

/* spec: minimum column width is 240px */
const BORDER = 1; // 0.5 left & right
const PADDING = 20; // 10 left & right

export const textMinWidth = 240 - (BORDER + PADDING) + "px";
export const Table = ({
  rows,
  columns,
  setColumns,
  sortSetting,
  setSortSetting,
  handleUnitsChange,
  onClickRow,
  units = {},
  getByPage,
  total,
  loading,
  page = 1,
  pagesize = 25
}) => {
  const { classes } = useTableStyles();
  const handlePageChange = (event, newPage) => getByPage(newPage, pagesize);

  const [columnToSort, setColumnToSort] = useState(
    sortSetting.columnToSort || columns?.[0]?.field
  );
  const [sortDirection, setSortDirection] = useState(
    sortSetting.sortDirection || "asc"
  );

  const hasPagination = rows.length > 0 && getByPage && total > pagesize;

  const onSort = (direction, col) => {
    const { field, isNumeric, isTshirtSize, isPoi } = col;

    const switchDirection = columnToSort === field;
    const invertedDirection = direction === "asc" ? "desc" : "asc";
    const sortDirection = switchDirection ? invertedDirection : "desc";

    setSortSetting({
      columnToSort: field,
      sortDirection,
      isNumeric,
      isTshirtSize,
      isPoi
    });
    setColumnToSort(field);
    setSortDirection(sortDirection);
  };

  return (
    <>
      <TableContainer className={classes.root}>
        <MaterialTable className={classes.table}>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              {columns?.map((col, index) => {
                return col?.columnVisibility ? (
                  col?.renderHeaderCellComponent ? (
                    <TableCell key={col?.headerName ?? index}>
                      <col.renderHeaderCellComponent>
                        <TruncatedTextWithTooltip minWidth={textMinWidth}>
                          {col?.headerName}
                        </TruncatedTextWithTooltip>
                      </col.renderHeaderCellComponent>
                    </TableCell>
                  ) : (
                    <TableHeaderCell
                      key={`${col?.headerName}-${index}`}
                      columnIdx={index}
                      headerName={col?.headerName}
                      tooltip={col?.tooltip}
                      unitsOfMeasurement={col?.unitsOfMeasurement}
                      sortable={col?.sortable}
                      handleSort={direction => onSort(direction, col)}
                      columnToSort={columnToSort}
                      sortDirection={sortDirection}
                      field={col?.field}
                      handleUnitsChange={e =>
                        handleUnitsChange(e, col?.fieldUnit)
                      }
                      units={units[col?.fieldUnit]}
                      fieldUnit={col?.fieldUnit}
                      withColumnMenu={col?.withColumnMenu}
                      withUnitsOptions={col?.withUnitsOptions}
                      withVisibilityOptions={col?.withVisibilityOptions}
                      columns={columns}
                      setColumns={setColumns}
                      sortIcon={col?.sortIcon}
                    />
                  )
                ) : null;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              rows?.map(row => (
                <TableRow
                  key={row.id}
                  onClick={() => onClickRow(row)}
                  className={onClickRow && classes.clickableRow}
                >
                  {columns?.map(col => {
                    return col?.columnVisibility ? (
                      col?.renderCellComponent ? (
                        <TableCell key={`${row?.id}-${col.field}`}>
                          <col.renderCellComponent
                            row={row}
                            className={classes.cameraNameRenderCell}
                          />
                        </TableCell>
                      ) : (
                        <TableCell key={`${row?.id}-${col.field}`}>
                          {row[col?.field]}
                        </TableCell>
                      )
                    ) : null;
                  })}
                </TableRow>
              ))}
          </TableBody>
        </MaterialTable>
      </TableContainer>
      {loading && (
        <Box sx={{ height: 150, width: "100vw" }}>
          <LoadingSpinner centered />
        </Box>
      )}
      {hasPagination && !loading && (
        <TablePagination
          component="div"
          labelDisplayedRows={() => ""}
          count={total || rows.length}
          rowsPerPageOptions={[pagesize]}
          rowsPerPage={pagesize}
          page={page}
          labelRowsPerPage=""
          onPageChange={handlePageChange}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </>
  );
};

Table.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  setColumns: PropTypes.func,
  sortSetting: PropTypes.object,
  setSortSetting: PropTypes.func,
  handleUnitsChange: PropTypes.func,
  onClickRow: PropTypes.func,
  units: PropTypes.object,
  getByPage: PropTypes.func,
  page: PropTypes.number,
  total: PropTypes.number,
  selectedOrgCameras: PropTypes.array,
  loading: PropTypes.bool,
  pagesize: PropTypes.number
};

import ImageIcon from "@mui/icons-material/Image";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef } from "react";

export const imageFrameTypes = Object.freeze({
  scanSwir: "jpg",
  scanRgb: "scanrgb",
  swir: "img",
  rgb: "rgb"
});

const MAX_IMAGES_PRELOAD = 5;

export const ImageFrames = ({
  type = imageFrameTypes.swir,
  classes,
  sortedFrames,
  imageErrors,
  frameIndex,
  setImageErrors,
  handleImageError
}) => {
  const preloadedImages = useRef([]);
  const onImageLoad = idx => () =>
    setImageErrors(prevState => ({ ...prevState, [idx]: false }));

  const preloadImages = useCallback(
    urls => {
      const loadPromises = urls.map((url, index) => {
        return new Promise((resolve, reject) => {
          const imageIndex = frameIndex + index;
          if (!preloadedImages.current[imageIndex]) {
            const img = new Image();
            img.onload = resolve;
            img.onerror = reject;
            img.src = url;
            preloadedImages.current[imageIndex] = img;
          } else {
            resolve();
          }
        });
      });

      return Promise.all(loadPromises);
    },
    [frameIndex]
  );

  useEffect(() => {
    preloadedImages.current = [];
  }, [type, sortedFrames]);

  useEffect(() => {
    const chunkOfFrames = sortedFrames
      .slice(frameIndex, frameIndex + MAX_IMAGES_PRELOAD)
      .map(frame => frame[type]);
    preloadImages(chunkOfFrames).catch(error => console.error(error));
  }, [frameIndex, sortedFrames, type, preloadImages]);

  return (
    <div className={classes.frame} data-testid={type}>
      {preloadedImages.current.map((img, index) => {
        const frame = sortedFrames[index];
        const key = `${frame?.scresId ?? frame?.id ?? index}-${type}`;
        const hasError = imageErrors[index];
        const src = img?.src;

        return hasError ? (
          <div key={key} className={clsx(classes.image, classes.imageError)}>
            <ImageIcon />
            <span>Not found</span>
          </div>
        ) : (
          <img
            key={key}
            src={src}
            alt={`Alarm ${type} frame ${index}`}
            className={classes.image}
            style={{ opacity: index === frameIndex ? 1 : 0 }}
            onLoad={onImageLoad(index)}
            onError={handleImageError(index)}
          />
        );
      })}
    </div>
  );
};

ImageFrames.propTypes = {
  type: PropTypes.oneOf(Object.values(imageFrameTypes)),
  classes: PropTypes.object,
  sortedFrames: PropTypes.arrayOf(PropTypes.object),
  imageErrors: PropTypes.object,
  frameIndex: PropTypes.number,
  setImageErrors: PropTypes.func,
  handleImageError: PropTypes.func
};

import { Popover } from "@mui/material";
import PropTypes from "prop-types";

import { MultiSelectPopoverContent } from "../multi-select-popover-content";
import { multiSelectPropsType } from "../types";

import { StyledContentWrapper } from "./styles";

export const MultiSelectPopover = ({
  triggerElement,
  contentTitle,
  multiSelectProps,
  searchPlaceholder,
  popoverProps
}) => {
  const { id, anchorEl, open, closePopover, width } = popoverProps;

  const onClose = () => {
    multiSelectProps.handleSearch();
    closePopover();
  };

  return (
    <div>
      {triggerElement}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <StyledContentWrapper width={width}>
          <MultiSelectPopoverContent
            title={contentTitle}
            searchPlaceholder={searchPlaceholder}
            multiSelectProps={multiSelectProps}
          />
        </StyledContentWrapper>
      </Popover>
    </div>
  );
};

MultiSelectPopover.propTypes = {
  triggerElement: PropTypes.element.isRequired,
  contentTitle: PropTypes.string.isRequired,
  multiSelectProps: multiSelectPropsType,
  searchPlaceholder: PropTypes.string,
  popoverProps: PropTypes.shape({
    id: PropTypes.string,
    anchorEl: PropTypes.object,
    open: PropTypes.bool.isRequired,
    closePopover: PropTypes.func.isRequired,
    width: PropTypes.number
  })
};

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import PropTypes from "prop-types";
import { useState } from "react";

import { TooltipHint } from "@kuva/ui-components";

import SortButton from "~/common/components/buttons/SortButton";
import TruncatedTextWithTooltip from "~/components/TruncatedTextWithTooltip";

import VisibilityOption from "./ColumnMenu/VisibilityOption";
import { textMinWidth } from "./Table";
import { useTableHeaderCellStyles } from "./styles";

import { ColumnMenu, TemperatureUnitOption } from "./";

const TableHeaderCell = ({
  columnIdx,
  field,
  headerName,
  tooltip,
  unitsOfMeasurement,
  align = "left",
  sortable = true,
  handleSort,
  sortDirection,
  columnToSort,
  handleUnitsChange,
  units,
  withColumnMenu = true,
  withUnitsOptions = false,
  withVisibilityOptions = true,
  columns,
  setColumns,
  sortIcon,
  fieldUnit,
  ...props
}) => {
  const { classes } = useTableHeaderCellStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <TableCell className={classes.root} align={align} {...props}>
      <Box className={classes.headerContentContainer}>
        <Box className={classes.sectionContainer}>
          {columnToSort === field && sortable === true ? (
            <Box className={classes.sortIcon}>
              <SortButton
                handleClick={() => handleSort(sortDirection)}
                manualSetting={sortDirection === "asc"}
                sortIcon={sortIcon}
              />
            </Box>
          ) : null}
          <Box
            onClick={() => handleSort(sortDirection)}
            className={classes.headerName}
          >
            <TruncatedTextWithTooltip minWidth={textMinWidth}>
              {headerName}
            </TruncatedTextWithTooltip>
          </Box>
          {tooltip && <TooltipHint title={tooltip} outlinedIcon small />}
        </Box>

        <Box className={classes.sectionContainer}>
          <Box className={classes.unitsOfMeasurement}>{unitsOfMeasurement}</Box>

          {withColumnMenu && (
            <MoreVertIcon
              className={classes.moreVertIcon}
              onClick={handleClick}
            />
          )}
        </Box>
        <ColumnMenu
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          {withVisibilityOptions && (
            <VisibilityOption
              index={columnIdx}
              columns={columns}
              setColumns={setColumns}
              field={field}
            />
          )}
          {withUnitsOptions && fieldUnit === "sensor_temperature" && (
            <TemperatureUnitOption
              handleUnitsChange={handleUnitsChange}
              units={units}
            />
          )}
        </ColumnMenu>
      </Box>
    </TableCell>
  );
};

TableHeaderCell.propTypes = {
  align: PropTypes.oneOf(["center", "inherit", "left", "right"]),
  columnIdx: PropTypes.number,
  columnToSort: PropTypes.string,
  columns: PropTypes.array,
  field: PropTypes.string,
  handleSort: PropTypes.func,
  handleUnitsChange: PropTypes.func,
  headerName: PropTypes.string,
  tooltip: PropTypes.string,
  setColumns: PropTypes.func,
  sortDirection: PropTypes.string,
  sortIcon: PropTypes.string,
  sortable: PropTypes.bool,
  units: PropTypes.string,
  fieldUnit: PropTypes.string,
  unitsOfMeasurement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element
  ]),
  withColumnMenu: PropTypes.bool,
  withUnitsOptions: PropTypes.bool,
  withVisibilityOptions: PropTypes.bool
};

export default TableHeaderCell;

import axios from "axios";
import Cookies from "js-cookie";

export const client = axios.create({
  baseURL: `${process.env.REACT_APP_KUVA_API_URL}/`,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  }
});

let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = token => {
  refreshSubscribers.forEach(callback => callback(token));
  refreshSubscribers = [];
};

const addRefreshSubscriber = callback => {
  if (!refreshSubscribers) {
    refreshSubscribers = [];
  }
  refreshSubscribers.push(callback);
};

export const setupInterceptors = (getAccessTokenSilently, logout) => {
  client.interceptors.request.use(async config => {
    const isRefreshTokenTimedOut = !Cookies.get("kuva.auth.refresh.token");
    if (isRefreshTokenTimedOut) logout();

    const token = Cookies.get("kuva.auth.jwt");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  });

  client.interceptors.response.use(
    response => response,
    async error => {
      if (error.response && error.response.status === 401) {
        if (isRefreshing) {
          return new Promise(resolve => {
            addRefreshSubscriber(token => {
              error.config.headers["Authorization"] = `Bearer ${token}`;
              resolve(client.request(error.config));
            });
          });
        }
        isRefreshing = true;
        try {
          const accessToken = await getAccessTokenSilently();
          Cookies.set("kuva.auth.jwt", accessToken);

          onRefreshed(accessToken);
          isRefreshing = false;

          error.config.headers["Authorization"] = `Bearer ${accessToken}`;
          return client.request(error.config);
        } catch (tokenError) {
          console.error("Error refreshing token:", tokenError);

          isRefreshing = false;
          refreshSubscribers.forEach(callback =>
            callback(Promise.reject(error))
          );
          refreshSubscribers = [];
          logout();
        }
      }

      return Promise.reject(error);
    }
  );
};

export const request = async (url, options) =>
  await client.request({
    method: "GET",
    withCredentials: process.env.NODE_ENV !== "development",
    url,
    ...options
  });

export const sendPostRequest = async ({ url, options = {}, data }) =>
  await client.request({
    method: "POST",
    withCredentials: process.env.NODE_ENV !== "development",
    url,
    data,
    ...options
  });

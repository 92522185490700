export const SOURCE_TAGS = {
  BDS: {
    name: "Blowdown Stack",
    code: "BDS",
    isValid: true
  },
  BV: {
    name: "Building Vent",
    code: "BV",
    isValid: true
  },
  COM: {
    name: "Combustor",
    code: "COM",
    isValid: true
  },
  C: {
    name: "Compressors",
    code: "C",
    isValid: true
  },
  DU: {
    name: "Dehydration Unit",
    code: "DU",
    isValid: true
  },
  F: {
    name: "Flares",
    code: "F",
    isValid: true
  },
  GPU: {
    name: "Gas Processing Unit",
    code: "GPU",
    isValid: true
  },
  HT: {
    name: "Heater Treater",
    code: "HT",
    isValid: true
  },
  OS: {
    name: "Off site",
    code: "OS",
    isValid: true
  },
  O: {
    name: "Other",
    code: "O",
    isValid: true
  },
  OOS: {
    name: "Out-of-scene",
    code: "OOS",
    isValid: false
  },
  S: {
    name: "Separators",
    code: "S",
    isValid: true
  },
  TT: {
    name: "Tanker Truck",
    code: "TT",
    isValid: true
  },
  T: {
    name: "Tanks",
    code: "T",
    isValid: true
  },
  U: {
    name: "Undefined",
    code: "U",
    isValid: false
  },
  VRU: {
    name: "VRU",
    code: "VRU",
    isValid: true
  },
  NA: {
    name: "N/A",
    code: "N/A",
    isValid: false
  },
  WH: {
    name: "Wellhead",
    code: "WH",
    isValid: true
  },
  TO: {
    name: "Thermal Oxidizers",
    code: "TO",
    isValid: true
  },
  G: {
    name: "Generator",
    code: "G",
    isValid: true
  },
  R: {
    name: "Reboiler",
    code: "R",
    isValid: true
  },
  MS: {
    name: "Meter Skid",
    code: "MS",
    isValid: true
  },
  PT: {
    name: "Pig Trap",
    code: "PT",
    isValid: true
  },
  V: {
    name: "Valve",
    code: "V",
    isValid: true
  },
  AGR: {
    name: "Acid Gas Removal",
    code: "AGR",
    isValid: true
  }
};

import { useMemo, useState } from "react";

import { useSearch } from "../search-bar";

import {
  filterSelectedItems,
  getSelectedValues,
  updateAll,
  updateItem
} from "./use-multi-select.utils";

export const useMultiSelect = (initItems = [], onChange) => {
  const [items, setItems] = useState(initItems);

  const numItems = items.length;
  const isSearchable = numItems > 3;
  const selectedItems = useMemo(
    () => filterSelectedItems(items, true),
    [items]
  );

  const numSelected = selectedItems.length;
  const hasSelected = numSelected ? numSelected > 0 : false;
  const selectedValues = hasSelected ? getSelectedValues(selectedItems) : "";
  const unselectedItems = useMemo(
    () => (isSearchable ? filterSelectedItems(items, false) : items),
    [isSearchable, items]
  );

  const { handleSearch, filteredArray: filteredItems } = useSearch(
    unselectedItems,
    "label"
  );

  const otherItems = isSearchable ? filteredItems : unselectedItems;
  const hasOtherItems = otherItems ? otherItems.length > 0 : false;

  const onSelect = event => {
    const { value, checked } = event.target;
    const updatedItems = updateItem(items, value, checked);
    setItems(updatedItems);
    onChange?.(getSelectedValues(filterSelectedItems(updatedItems, true)));
  };

  const onClearAll = () => {
    const updatedItems = updateAll(items, false);
    setItems(updatedItems);
    onChange?.(getSelectedValues(initItems));
  };

  const onSelectAll = () => {
    const updatedItems = updateAll(items, true, filteredItems);
    setItems(updatedItems);
    onChange?.(getSelectedValues(updatedItems));
  };

  const onRemoveValue = value => {
    const updatedItems = updateItem(items, value, false);
    setItems(updatedItems);
    onChange?.(getSelectedValues(filterSelectedItems(updatedItems, true)));
  };

  const showClearAll = numSelected === numItems;

  return {
    onSelect,
    onClearAll,
    onSelectAll,
    onRemoveValue,
    isSearchable,
    selectedItems,
    hasSelected,
    otherItems,
    hasOtherItems,
    handleSearch,
    showClearAll,
    selectedValues
  };
};

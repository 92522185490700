import { useCallback, useEffect } from "react";
import { isEmpty } from "lodash";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { useSnackbar } from "@kuva/ui-components";
import { ORG_BASE_OVERRIDES } from "@kuva/ui-helpers/src/constants";

import { setHeaders } from "~/utils/api";
import { OrganizationActions } from "~/store/slices/organizations";
import { selectOrganizationState } from "~/selectors/OrganizationsSelector";

const routes = [
  "account-settings",
  "settings",
  "profile",
  "cameras",
  "organization-settings",
  "organizations-administration"
];

export const useOrganization = () => {
  const dispatch = useDispatch();

  const organizationState = useSelector(selectOrganizationState, shallowEqual);
  const {
    loading,
    organizations,
    normalizedOrgs,
    selectedOrg,
    selectedOrgWithDescendants,
    selectedOrgIDs
  } = organizationState;

  const { showSnackbar } = useSnackbar();

  const setOrganizations = organizations => {
    dispatch(OrganizationActions.setOrganizations(organizations));
  };

  const setSelectedOrg = useCallback(
    org => {
      if (isEmpty(org)) {
        showSnackbar(`Unable to find selected organization`, {
          variant: "error"
        });
        return;
      }
      dispatch(OrganizationActions.setSelectedOrg(org));
    },
    [dispatch]
  );

  const updateSelectedOrg = useCallback(
    org => dispatch(OrganizationActions.updateOrganization(org)),
    [dispatch]
  );

  const findOrg = useCallback((tree, value, reverse = false) => {
    if (!tree) return null;

    for (const node of reverse ? [...tree].reverse() : tree) {
      if (node.id === value) return node;

      if (node.children) {
        const result = findOrg(node.children, value, reverse);
        if (result) return result;
      }
    }
  }, []);

  const handleOrganizationChange = useCallback(
    org => {
      const orgId = typeof org === "string" ? org : org.id;

      if (routes.includes(orgId)) return;

      const newOrgSelected =
        typeof org === "string" ? findOrg(organizations, orgId) : org;

      setSelectedOrg(newOrgSelected);
    },
    [findOrg, organizations, setSelectedOrg]
  );

  useEffect(() => {
    let isMounted = true;

    if (selectedOrg && isMounted) {
      setHeaders(ORG_BASE_OVERRIDES, selectedOrg.id);
    }

    return () => {
      isMounted = false;
    };
  }, [selectedOrg]);

  return {
    setOrganizations,
    setSelectedOrg: handleOrganizationChange,
    updateSelectedOrg,
    organizations,
    selectedOrg,
    normalizedOrgs,
    selectedOrgWithDescendants,
    selectedOrgIDs,
    loading
  };
};

import BaseApi from "./BaseApi";

class CameraAPI extends BaseApi {
  constructor() {
    super(`${process.env.REACT_APP_KUVA_API_URL}/camera/v1`, 45000);
    this.getFramesCallStack = [];
  }

  get = async (orgId, showArchived) => {
    const retries = 2;
    const timeouts = [45000, 60000];
    let attempt = 0;
    let success = false;
    let response;

    while (attempt < retries && !success) {
      try {
        response = await this.axiosInstance.get(`cameras/${orgId}`, {
          params: {
            ...(showArchived ? { showArchived: showArchived } : {})
          },
          timeout: timeouts[attempt] // Set timeout based on current attempt
        });

        success = true; // Exit loop if API call is successful
      } catch (error) {
        attempt += 1;
        console.error("API error or timeout:", error);

        if (error.code === "ECONNABORTED") {
          if (attempt >= retries) {
            throw error; // Rethrow error after retries
          }
        } else {
          // Rethrow non-timeout errors immediately
          throw error;
        }
      }
    }

    // Always return the response as a promise, even after retries
    if (response) {
      return Promise.resolve(response); // Return the successful response
    } else {
      return Promise.reject(new Error("No response received after retries")); // Fail the promise if no response
    }
  };

  getById = which => {
    return this.axiosInstance.get("camera/", {
      params: {
        id: which
      },
      timeout: 30000
    });
  };

  getDistanceSegments = ({ deviceId, organizationId }) =>
    this.axiosInstance.get(`cameras/${deviceId}/distance-segments`, {
      params: { organizationId }
    });

  getFrames = async (camera, startDate, endDate, max) =>
    this.axiosInstance.get(`/scans/${camera}`, {
      params: {
        max_frames: max ?? (startDate || endDate ? 2000 : 100),
        ...(startDate && { start: startDate }),
        ...(endDate && { end: endDate })
      }
    });

  getLongTasks = () => {
    return this.axiosInstance.get("longtasks");
  };

  getModuleTwinForCamera = (camera, module) =>
    this.axiosInstance.get(`cameramodule/${camera}/${module}`);

  panCamera = (camera, direction, steps) => {
    console.log(`calling ${this.axiosInstance.defaults.baseURL}/pan/${camera}`);
    return this.axiosInstance.get(`pan/${camera}`, {
      params: {
        ...(direction ? { direction: direction } : {}),
        ...(steps ? { steps: steps } : {})
      }
    });
  };

  toggleCameraScan = which => {
    console.log(`calling: ${this.axiosInstance.defaults.baseURL}/togglescan`);
    return this.axiosInstance.get("togglescan", {
      params: {
        // code: "5xH8lNy6SqgjKMt6LxlAot0McOzJI9mXcAd2vKXGtnnrQJnBOLhX/A==",
        deviceId: which
      }
    });
  };

  toggleIlluminator = which => {
    console.log(
      `calling: ${this.axiosInstance.defaults.baseURL}/toggleilluminate`
    );
    return this.axiosInstance.get("toggleilluminate", {
      params: {
        deviceId: which
      }
    });
  };

  darkcal = which => {
    console.log(`calling: ${this.axiosInstance.defaults.baseURL}/v1/calibrate`);
    return this.axiosInstance.get("calibrate", {
      params: {
        deviceId: which
      }
    });
  };

  capture = which => {
    console.log(`calling: ${this.axiosInstance.defaults.baseURL}/capture`);
    return this.axiosInstance.get("capture", {
      params: {
        // code: "uSy1hwZ2/iWmsUiM/L99t3MSbxtaPwpkwtN6qGuQeRjwrQCJAe4EOQ==",
        deviceId: which
      }
    });
  };

  requestPano = camera => {
    return this.axiosInstance.get(`getpanorama/${camera}/`);
  };

  panZero = (camera, deg, updatePano) => {
    return this.axiosInstance.post(`panzero/${camera}/`, {
      ...(deg !== null && deg !== undefined ? { degrees: deg } : {}),
      ...(updatePano ? { getPano: updatePano } : {})
    });
  };

  previewPoi = (camera, deg) => {
    return this.axiosInstance.get(`previewpoi/${camera}/`, {
      params: {
        ...(deg !== null && deg !== undefined ? { degrees: deg } : {})
      }
      // timeout: 60000
    });
  };

  configPoi = (camera, dwell_frames, pan_centers) => {
    return this.axiosInstance.post(`poi/${camera}`, {
      dwell_frames,
      pan_centers
    });
  };

  getFovImage = (camera, degrees) => {
    if (degrees !== null && degrees !== undefined) {
      return this.axiosInstance.get(`fov/blobrgb/${camera}/${degrees}`);
    } else {
      return this.axiosInstance.get(`fov/blobrgb/${camera}`);
    }
  };

  /**
   * @description call the command azure function that will send a get request to resetalgo
   * resetalgo will clear algorithm history so that we can collect clean data.
   * @memberof CameraAPI
   * @param {string} deviceId
   * @returns {Promise}
   */
  resetalgo = deviceId => {
    console.log(
      `calling: ${this.axiosInstance.defaults.baseURL}/command/${deviceId}/resetalgo`
    );
    return this.axiosInstance.get(`command/${deviceId}/resetalgo`);
  };

  updateDeviceAttributes = payload =>
    this.axiosInstance.put(`camera/${payload.deviceId}`, payload);

  updateCameraProperties = payload =>
    this.axiosInstance.patch(`cameras/${payload.deviceId}/properties`, payload);

  pauseOnPoi = (deviceId, body) => {
    console.log(
      `calling: ${this.axiosInstance.defaults.baseURL}/pauseOnPoi/${deviceId}`
    );
    return this.axiosInstance.put(`/pauseOnPoi/${deviceId}`, body);
  };

  getScheduleForCamera = camera => {
    console.log(
      `%c calling: ${this.axiosInstance.defaults.baseURL}/schedule/${camera}`,
      "color: #9954E3"
    );
    return this.axiosInstance.get(`schedule/${camera}`);
  };

  /**
   * @function setScanningSchedule - updates the device twin tags with the new schedule
   *
   * @param {string} deviceId - the device to update the schedule for
   * @param {string} onUtc - the time to switch scanning on in the format HH:MM
   * @param {string} offUtc - the time to switch scanning off in the format HH:MM
   * @returns {Promise}
   */
  setScanningSchedule = (deviceId, onUtc, offUtc) => {
    console.log(
      `calling: ${this.axiosInstance.defaults.baseURL}/schedule/${deviceId}`
    );
    return this.axiosInstance.post(`schedule/${deviceId}/`, { onUtc, offUtc });
  };
  /**
   * @function setIlluminatingSchedule - updates the device twin tags with the new schedule
   *
   * @param {string} deviceId - the device to update the schedule for
   * @param {string} illuminatoronutc - the time to switch illuminating on in the format HH:MM
   * @param {string} illuminatoroffutc - the time to switch illuminating off in the format HH:MM
   * @returns {Promise}
   */
  setIlluminatingSchedule = (deviceId, illuminatoronutc, illuminatoroffutc) => {
    console.log(
      `calling: ${this.axiosInstance.defaults.baseURL}/schedule/${deviceId}`
    );
    return this.axiosInstance.post(`schedule/${deviceId}`, {
      illuminatoronutc,
      illuminatoroffutc
    });
  };

  getScanResultsForPoi = async ({
    deviceId,
    max_live_frames = 1,
    cancelToken
  }) =>
    this.axiosInstance.get(`scres/${deviceId}/scanresult`, {
      params: { max_live_frames },
      cancelToken
    });

  getPoiNames = ({ streamIds, poi, cancelToken }) => {
    const uniqueStreamIds = new Set(streamIds.split(","));
    return this.axiosInstance.get(`getPoiNames`, {
      params: { streamIds: [...uniqueStreamIds].join(","), poi },
      cancelToken
    });
  };

  upsertPoiName = body => this.axiosInstance.put("upsertPoiName", body);

  deletePoiName = ({ poi, id }) =>
    this.axiosInstance.delete(`deletePoiName/${id}/${poi}`);

  setSerialNumber = ({ deviceId, serialNumber }) =>
    this.axiosInstance.post(`cameras/${deviceId}/serial-number`, {
      serialNumber
    });

  removeSerialNumber = ({ deviceId }) =>
    this.axiosInstance.delete(`cameras/${deviceId}/serial-number`);

  timedScan = ({ deviceId, ...body }) =>
    this.axiosInstance.post(`${deviceId}/timed-scan`, {
      body
    });

  createStream = ({ deviceId }) =>
    this.axiosInstance.post(`streams`, {
      deviceId
    });

  getStreams = ({ orgId, showArchived = false }) =>
    this.axiosInstance.get(`streams`, {
      params: {
        organizationId: orgId,
        showArchived
      }
    });

  assignStreamDevice = ({ streamId, deviceId }) =>
    this.axiosInstance.post(`streams/${streamId}/assign-device`, {
      deviceId
    });

  replaceStreamDevice = ({ streamId, deviceId }) =>
    this.axiosInstance.post(`streams/${streamId}/replace-device`, {
      deviceId
    });

  unassignStreamDevice = ({ streamId }) =>
    this.axiosInstance.post(`streams/${streamId}/unassign-device`);

  setDaylightScanning = ({ deviceId, enabled }) =>
    this.axiosInstance.post(`cameras/${deviceId}/daylight-scanning`, {
      enabled
    });
}

export default CameraAPI.Instance();

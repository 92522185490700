import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useSnackbar } from "@kuva/ui-components";

import { useOrganization } from "~/hooks";
import { getOrgCameras } from "~/selectors/CameraSelector";
import { CameraActions } from "~/store/slices/cameras";

/**
 * Updates camera attributes
 * Example use:
 * const { handleBlur, handleSerialNumberBlur } = useUpdateCameraAttributes(camera,formik);
 *
 * @param deviceId to update specific camera
 * @param {*} assetId
 * @param {* boolean} archived to toggle archive state
 * @param {number} latitude - Latitude for the device location
 * @param {number} longitude - Longitude for the device location
 * @param {* string} name human readable name of device
 *
 * @returns {Object}
 */

export const useUpdateCameraAttributes = (camera, formik) => {
  const { showSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { selectedOrgIDs } = useOrganization();
  const cameras = useSelector(getOrgCameras(selectedOrgIDs), shallowEqual);
  const deviceId = camera?.deviceId;

  const getCameraAttributes = ({ initialValues, values }) => {
    const updatedAttributes = { deviceId };

    for (let key in initialValues) {
      // Only include values that have changed
      if (initialValues[key] !== values[key]) {
        updatedAttributes[key] = values[key];
      }
    }

    return updatedAttributes;
  };

  const handleBlur = async event => {
    if (!toSubmitField(event)) {
      return;
    }

    formik.handleBlur(event);

    try {
      await verifyUniqueCameraName(formik.values.name, camera, cameras);

      const result = await dispatch(
        CameraActions.updateCameraAttributesByDeviceId(
          getCameraAttributes(formik)
        )
      );
      if (result.error) throw Error("An error occurred saving camera name");

      showSnackbar(`Camera: updated successfully`, {
        variant: "success"
      });
    } catch (error) {
      showSnackbar(error.message, {
        variant: "error"
      });
    }
  };

  const handleSerialNumberBlur = async event => {
    if (!toSubmitField(event)) {
      return;
    }

    formik.handleBlur(event);

    if (formik.errors.serialNumber || !deviceId) {
      return;
    }

    try {
      await dispatch(
        CameraActions.setSerialNumber({
          deviceId,
          serialNumber: formik.values.serialNumber
        })
      ).unwrap();

      showSnackbar(`Camera serial number updated successfully`, {
        variant: "success"
      });
    } catch (error) {
      showSnackbar(error.message, {
        variant: "error"
      });
    }
  };

  const handleSaveSerialNumber = async deviceId => {
    const { serialNumber } = formik.values;

    if (!serialNumber || !deviceId) {
      showSnackbar("Serial number or device ID is missing.", {
        variant: "error"
      });
      return;
    }

    try {
      await dispatch(
        CameraActions.setSerialNumber({
          deviceId,
          serialNumber
        })
      ).unwrap();

      showSnackbar(`Camera serial number updated successfully`, {
        variant: "success"
      });
    } catch (error) {
      showSnackbar(error.message, {
        variant: "error"
      });
    }
  };

  const handleSaveProperties = async deviceId => {
    const { name, timezone, longitude, latitude } = formik.values;
    try {
      await verifyUniqueCameraName(formik.values.name, camera, cameras);

      await dispatch(
        CameraActions.updateCameraProperties({
          deviceId,
          name,
          timezone,
          longitude,
          latitude
        })
      ).unwrap();

      showSnackbar(`Camera properties updated successfully`, {
        variant: "success"
      });
    } catch (error) {
      showSnackbar("Failed to update the camera properties", {
        variant: "error"
      });
    }
  };

  const toSubmitField = event =>
    (event.type === "blur" || event.key === "Enter") && formik.dirty;

  return {
    handleBlur,
    handleSaveProperties,
    handleSaveSerialNumber,
    handleSerialNumberBlur
  };
};

export const CAMERA_NAME_ERROR_MESSAGE =
  "Camera name already exists in organization";
export const verifyUniqueCameraName = (
  newCameraName,
  selectedCamera,
  cameras
) => {
  const nameExists = cameras.some(
    camera =>
      camera.deviceId !== selectedCamera.deviceId &&
      camera.orgID === selectedCamera.orgID &&
      camera.name?.toLowerCase() === newCameraName?.toLowerCase()
  );

  if (nameExists) {
    throw new Error(CAMERA_NAME_ERROR_MESSAGE);
  }
};

import StrapiAPI from "./StrapiAPI";

class UserNotificationAPI {
  constructor() {
    this.axiosInstance = StrapiAPI.axiosInstance;
  }

  static instance = null;

  static Instance = () => this.instance ?? new UserNotificationAPI();

  getUserNotifications = startDate =>
    this.axiosInstance.get(`/all-notifications-by-start-date/${startDate}`);
}

export default UserNotificationAPI.Instance();

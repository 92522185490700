import { createAsyncThunk } from "@reduxjs/toolkit";

import { getActiveAlarm } from "~/selectors/AlarmSelector";
import AlarmAPI from "~/apis/AlarmAPI";

const options = {
  condition: (alarm, { getState }) => {
    const state = getState();
    const activeAlarm = getActiveAlarm(state);

    return activeAlarm?.id !== alarm?.alarmId;
  }
};

const thunk = async ({ deviceId, alarmId, streamId }) => {
  const { data } = await AlarmAPI.get(deviceId, alarmId, streamId);

  return data;
};

export const getAlarmById = createAsyncThunk(
  "alarms/getAlarmById",
  thunk,
  options
);

import { reduce, isEmpty } from "lodash/fp";

const getFlattenOrgIds = org => {
  if (isEmpty(org)) return [];
  return org.children
    ? [org.id, ...org.children.flatMap(getFlattenOrgIds)]
    : org.id;
};
/* Find organization and descendant and return as an Object(organizationWithDescendant). */
function findOrganizationAndDescendant(orgs, id) {
  // recursive func
  let i, temp;
  // loop to parent object
  for (i = 0; i < orgs?.length; i++) {
    if (orgs[i].id === id) {
      return orgs[i];
    }
    // Find children / descendant

    if (orgs[i].children.length > 0) {
      // Find children recursively
      temp = findOrganizationAndDescendant(orgs[i].children, id);
      if (temp) {
        return temp;
      }
    }
  }
  return null;
}

function getChildren(org, iotHub = null) {
  const currentIotHub = iotHub || org.id;

  let children = [
    {
      ...org,
      parent: org.parent || 0,
      droppable: true,
      iotHub: currentIotHub
    }
  ];

  if (Array.isArray(org.children)) {
    for (let child of org.children) {
      children = children.concat(
        getChildren({ ...child, parent: org.id }, currentIotHub)
      );
    }
  }

  return children.map(child => {
    // eslint-disable-line no-unused-vars
    const { children, ...rest } = child;
    return rest;
  });
}

function createOneLevelOrgsArray(rawOrgs) {
  let orgs = [];
  for (let org of rawOrgs) {
    orgs = orgs.concat(getChildren(org));
  }

  /* Return all orgs as one level array */
  /* eg. [{ id: "kuva", parent: 0 , ... }, { id: "kuva-usa", parent: "kuva" , ... }, ...all orgs and children] */
  return orgs;
}

function normalizeOrgs(rawOrgs) {
  const orgs = createOneLevelOrgsArray(rawOrgs);
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base"
  });
  const sortedOrgs = orgs.sort((a, b) =>
    collator.compare(a.orgname, b.orgname)
  );

  return reduce(
    (acc, org) => {
      acc[org.id] = org;
      return acc;
    },
    {},
    sortedOrgs
  );
}

export {
  createOneLevelOrgsArray,
  findOrganizationAndDescendant,
  getChildren,
  getFlattenOrgIds,
  normalizeOrgs
};
